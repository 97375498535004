<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "ObjectRelations.EditObjectRelation",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="detailRequestEnd && lookupRelationData.isOk"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <SetLoader v-if="!detailRequestEnd" />
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && lookupRelationData.isOk"
    class="form-object-relation-edit pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "ObjectRelations.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="lookupRelationData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "ObjectRelations.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control text-uppercase"
          id="FormulaName"
          disabled
          :value="lookupRelationData.formulaName"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="PageSize" class="form-label required">{{
          $t(
            "ObjectRelations.PageSize",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="number"
          min="0"
          class="form-control"
          id="PageSize"
          v-model="lookupRelationData.pageItemCount"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="LookupObject" class="form-label required">{{
          $t(
            "ObjectRelations.LookupObjectName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeLookupObject"
          :selectedData="selectedLookupObject"
          :isDetailIconShow="true"
          selectedPrimaryKey="publicId"
          :routeLink="String.format('#/CustomObject/Detail/')"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryLookupObjectsWithFormula?customObjectFormulaName={0}',
              this.lookupRelationData.customObjectFormulaName
            )
          "
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="Viewfilter" class="form-label required">{{
          $t(
            "ObjectRelations.ViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeSystemViewFilter"
          :resetSelect="selectValueReset"
          :selectedData="selectedLookupObjectViewFilter"
          :isDetailIconShow="true"
          selectedPrimaryKey="publicId"
          :routeLink="
            String.format(
              '#/CustomObject/EditViewFilter/{0}&viewFilterId=',
              this.lookupRelationData.lookupObjectPublicId
            )
          "
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummarySystemViewFiltersWithFormula?coFormulaName={0}',
              this.lookupRelationData.lookupObjectFormulaName
            )
          "
        />
      </div>
      <div class="col col-md-6">
        <label for="FieldPublicId" class="form-label required">{{
          $t(
            "ObjectRelations.FieldName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :isDetailIconShow="true"
          selectedPrimaryKey="publicId"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.lookupRelationData.lookupObjectPublicId
            )
          "
          @onChange="onChangeField"
          :resetSelect="selectValueReset"
          :selectedData="selectedLookupObjectField"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryLookupFieldsWithFormula?type=Lookup&parentFormulaName={0}&query={1}',
              this.lookupRelationData.customObjectFormulaName,
              this.lookupRelationData.lookupObjectFormulaName
            )
          "
        />
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="form-check">
        <label for="ShowInNewPage" class="form-label">{{
          $t(
            "ObjectRelations.ShowInNewPage",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="ShowInNewPage"
          v-model="lookupRelationData.isShowInNewPage"
        />
      </div>
      <div class="form-check">
        <label for="ShowInEditPage" class="form-label">{{
          $t(
            "ObjectRelations.ShowInEditPage",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="ShowInEditPage"
          v-model="lookupRelationData.isShowInEditPage"
        />
      </div>
      <div class="form-check">
        <label for="IsItLoadedOnFirstRequest" class="form-label">{{
          $t(
            "ObjectRelations.IsItLoadedOnFirstRequest",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsItLoadedOnFirstRequest"
          v-model="lookupRelationData.isLoadedOnFirstRequest"
        />
      </div>
      <div class="form-check">
        <label
          for="IsSaveAndContinueButtonEnabledOnWindowOpener"
          class="form-label"
          >{{
            $t(
              "ObjectRelations.IsSaveAndContinueButtonEnabledOnWindowOpener",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label
        >
        <input
          class="form-check-input"
          type="checkbox"
          id="IsSaveAndContinueButtonEnabledOnWindowOpener"
          v-model="
            lookupRelationData.isSaveAndContinueButtonEnabledOnWindowOpener
          "
        />
      </div>
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="lookupRelationData.isActive"
        />
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('btn-save')"
      :isNewRecord="false"
    />
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectEditObjectRelation",
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      lookupRelationData: {},
      selectedLookupObjectField: [],
      selectedLookupObjectViewFilter: [],
      selectedLookupObject: [],
      selectValueReset: false,
      detailRequestEnd: false,
      nullObject: [{ key: null, value: null }],
    };
  },
  methods: {
    onChangeLookupObject: function (selected) {
      this.lookupRelationData.lookupObjectFormulaName = selected.key;
      this.selectValueReset = !this.selectValueReset;
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.onChangeSystemViewFilter(this.nullObject);
        this.onChangeField(this.nullObject);
      }
    },
    onChangeSystemViewFilter: function (selected) {
      this.lookupRelationData.viewFilterFormulaName = selected.key;
      this.lookupRelationData.viewFilterName = selected.value;
    },
    onChangeField: function (selected) {
      this.lookupRelationData.fieldFormulaName = selected.key;
    },
    onSubmit(buttonName) {
      var form = $(".form-object-relation-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-LookupRelationEdit", { ...this.lookupRelationData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/ObjectRelations/" +
                this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getObjectRelation() {
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-LookupRelationGet?id={0}&coId={1}",
            this.$route.params.objectRelationId,
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.detailRequestEnd = true;
          this.lookupRelationData = response.data;

          if (response.data.isOk) {
            if (
              !String.isNullOrWhiteSpace(
                this.lookupRelationData.lookupObjectName
              ) &&
              !String.isNullOrWhiteSpace(
                this.lookupRelationData.lookupObjectPublicId
              )
            ) {
              this.selectedLookupObject.push({
                publicId: this.lookupRelationData.lookupObjectPublicId,
                key: this.lookupRelationData.lookupObjectFormulaName,
                value: this.lookupRelationData.lookupObjectName,
              });
            }

            if (
              !String.isNullOrWhiteSpace(
                this.lookupRelationData.viewFilterName
              ) &&
              !String.isNullOrWhiteSpace(
                this.lookupRelationData.viewFilterPublicId
              )
            ) {
              this.selectedLookupObjectViewFilter.push({
                publicId: this.lookupRelationData.viewFilterPublicId,
                key: this.lookupRelationData.viewFilterPublicId,
                value: this.lookupRelationData.viewFilterName,
              });
            }

            if (
              !String.isNullOrWhiteSpace(this.lookupRelationData.fieldName) &&
              !String.isNullOrWhiteSpace(this.lookupRelationData.fieldPublicId)
            ) {
              this.selectedLookupObjectField.push({
                publicId: this.lookupRelationData.fieldPublicId,
                key: this.lookupRelationData.fieldPublicId,
                value: this.lookupRelationData.fieldName,
              });
            }

            this.lookupRelationData.customObjectFormulaName =
              this.$store.getters._customObjectDto.formulaName;
          } else {
            this.errors.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getObjectRelation();
  },
};
</script>
